
import { modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";

//组件
import moment from "moment";
import Header from "@/components/Header.vue";
import SystemUtils from "@/utils/SystemUtils";
import Utils from "@/utils/Utils";
import SelectUserDeptTeam from "@/components/meeting/SelectUserDeptTeam.vue";
import { MeetingUserService } from "@/services/meeting/MeetingUserService.ts";
import { MeetingRoomService } from "@/services/meeting/MeetingRoomService.ts";
import { MeetingBookService } from "@/services/meeting/MeetingBookService.ts";
import SelectExecutor from "@/components/selectexecutor/SelectExecutor.vue";
import { Network } from "@ionic-native/network";

export default defineComponent({
  name: "AddCalendar",
  components: {
    Header,
  },
  data() {
    const router = useRouter();

    return {
      meetingService: new MeetingRoomService(),
      meetingUserService: new MeetingUserService(),
      meetingBookService: new MeetingBookService(),
      router: router,
      useRoute: useRoute(),
      btnIconRight: ["iconduihao"],
      btnSizeRight: ["34px"],
      colorList: new Array(),
      id: "",
      title: "",
      isClick: false,
      jumpSelect: true,
      roomId: "",
      roomName: "",
      startDate: "", //日期
      beginTime: "", //开始时间
      endTime: "", //结束时间
      remark: "",
      rowsUser: new Array(),
      showUserList: new Array(),
      name: "",
      status: 0, //状态
      statusName: "",
      isClean: 0,
      cancelDate: "",
      cancelReason: "",
      userId: "",
      userName: "",
      type: -1, // -1:表明不是从消息提醒跳转到该页面  1：从消息提醒跳转到该页面
    };
  },
  created() {
    //无网络连接
    if (Network.type === "none" || Network.type === "NONE") {
      Utils.presentToastWarning("网络连接失败，请检查网络");
    }
    if (this.useRoute.query && this.useRoute.query.id) {
      this.id = this.useRoute.query.id as string;
      this.getDetail();
    }
    if (this.useRoute.query && this.useRoute.query.type) {
      this.type = parseInt(this.useRoute.query.type as string);
    }
  },
  methods: {
    initPageValue() {
      const currentUser: any = SystemUtils.loginUser;
      //获取当前人信息
      const user = {
        id: currentUser.id,
        userId: currentUser.id,
        name: currentUser.name,
      };
    },
    subStringUserName(userName: string) {
      if (!userName) {
        return "";
      }
      return Utils.subStringUserName(userName, 2);
    },

    getDetail() {
      const currentUser: any = SystemUtils.loginUser;
      let params = { id: this.id };
      this.meetingBookService
        .getBookDetail(params)
        .then((result: any) => {
          this.name = result.name;
          this.roomName = result.roomName;
          this.beginTime = result.beginTime;
          this.endTime = result.endTime;
          this.remark = result.remark;
          this.status = result.status;
          this.statusName = result.statusName;
          this.isClean = result.isClean;
          this.cancelDate = result.cancelDate;
          this.cancelReason = result.cancelReason;
          this.userId = result.userId;
          this.userName = result.userName;
          this.rowsUser = result.meettingUserList;
          this.formatUserList();
        })
        .catch((ex: any) => {
          if (ex && ex.data && ex.data.error) {
            Utils.presentToastWarning(ex.data.error);
          }
        });
    },
    formatUserList() {
      let that = this;
      that.showUserList = new Array();
      that.rowsUser.forEach((ele: any) => {
        let user = {
          id: ele.id,
          userName: ele.userName,
          userId: ele.userId,
        };
        that.showUserList.push(user);
      });
    },
    //结束使用 取消预订:0 预约中 1 取消 2 结束
    bindUpdateBook(status: any): void {
      let title = "取消预订";
      if (status == "2" || status == 2) {
        title = "结束使用";
      }
      let that = this;
      Utils.confirmDialog("确认", "请确认是否" + title, function () {
        let params = {
          id: that.id, //会议室预定记录标识
          status: status, //状态：0 预约中 1 取消 2 结束
          cancelReason: that.cancelReason, // 取消原因(取消操作时填写)
        };
        that.meetingBookService
          .updateBookDetail(params)
          .then((result: any) => {
            Utils.presentToastSuccess(title + "成功");
            that.router.go(-1);
          })
          .catch((ex: any) => {
            if (ex && ex.data && ex.data.error) {
              Utils.presentToastWarning(ex.data.error);
            }
          });
      });
    },
  },
});
